header {
  padding-top: 10px;
  padding-bottom: 10px; }
  @media (min-width: 768px) {
    header {
      padding: 10px; } }

.bm-item {
  display: inline-block;
  /* Our sidebar item styling */
  text-decoration: none;
  padding: 10px;
  color: #d1d1d1;
  transition: color 0.2s; }
  .bm-item:focus {
    outline: none; }

.bm-item:hover {
  color: #26a69a; }

.bm-burger-button {
  position: absolute;
  width: 36px;
  height: 30px;
  right: 36px;
  top: 27px;
  display: block; }
  @media (min-width: 768px) {
    .bm-burger-button {
      display: none; } }

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: #263238; }

/* Position and sizing of clickable cross button */
.bm-cross-button {
  height: 24px;
  width: 24px; }

/* Color/shape of close button cross */
.bm-cross {
  background: #bdc3c7; }

/* General sidebar styles */
.bm-menu {
  background: #263238;
  padding: 2.5em 1.5em 0;
  font-size: 1.15em; }

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: #373a47; }

/* Wrapper for item list */
.bm-item-list {
  color: #b8b7ad; }

/* Styling of overlay */
.bm-overlay {
  background: rgba(0, 0, 0, 0.3); }

.MuiFormControl-root {
  width: 100%; }

body, #root {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
  flex-direction: column;
  height: 100vh;
  max-height: 100vh;
  color: #333;
  padding: 0;
  margin: 0;
  font-family: 'Roboto Mono';
  font-weight: 400; }

@media (max-width: 767.98px) {
  h2 {
    font-size: 40px;
    margin-bottom: 5px; } }

.MuiToolbar-root {
  padding-left: 0 !important;
  padding-right: 0 !important; }

.desk-nav {
  display: none; }
  @media (min-width: 768px) {
    .desk-nav {
      display: -webkit-flex;
      display: flex;
      margin-right: 30px; } }

.odd-wrap {
  padding: 15px 0;
  background-color: #EEE; }

.about-resume {
  text-align: center; }
  .about-resume > h1 {
    margin: 0 0 5px 0;
    letter-spacing: -1px; }
  .about-resume > p {
    margin: 0 0 25px 0; }

.MuiTypography-h6 {
  text-transform: uppercase; }

.picture {
  width: 150px;
  height: 150px;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  border-radius: 50%;
  overflow: hidden;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  margin-bottom: 15px; }

.social {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
  justify-content: center; }
  .social a {
    color: #263238; }

.home-resume {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
  flex-direction: column;
  margin-top: 30px;
  /*@include media-breakpoint-up(md) {
		@include flex-direction(row);
	}*/
  padding: 10px;
  /*.resume,.skills{
		@include media-breakpoint-up(md) {
			width:50%;
		}
	}*/ }
  .home-resume p {
    line-height: 23px; }
  .home-resume .skills {
    /*@include media-breakpoint-up(md) {
			padding-left:30px;
		}*/ }
    .home-resume .skills p {
      display: -webkit-flex;
      display: flex; }
      .home-resume .skills p .material-icons {
        margin-right: 15px; }
    .home-resume .skills .skills-list {
      display: -webkit-flex;
      display: flex;
      -webkit-flex-direction: column;
      flex-direction: column; }
      @media (min-width: 768px) {
        .home-resume .skills .skills-list {
          -webkit-flex-direction: row;
          flex-direction: row;
          -webkit-flex-wrap: wrap;
          flex-wrap: wrap; } }

footer {
  margin-top: auto; }

/* Skills */
.skillbar {
  position: relative;
  display: block;
  margin-bottom: 15px;
  width: 100%;
  background: #eee;
  height: 35px;
  border-radius: 3px;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  -webkit-transition: 0.4s linear;
  -moz-transition: 0.4s linear;
  -ms-transition: 0.4s linear;
  -o-transition: 0.4s linear;
  transition: 0.4s linear;
  -webkit-transition-property: width, background-color;
  -moz-transition-property: width, background-color;
  -ms-transition-property: width, background-color;
  -o-transition-property: width, background-color;
  transition-property: width, background-color; }
  @media (min-width: 768px) {
    .skillbar {
      width: 46%;
      margin-right: 2%; } }

.skillbar-title {
  position: absolute;
  top: 0;
  left: 0;
  width: 110px;
  font-weight: bold;
  font-size: 13px;
  color: #ffffff;
  -webkit-border-top-left-radius: 3px;
  -webkit-border-bottom-left-radius: 4px;
  -moz-border-radius-topleft: 3px;
  -moz-border-radius-bottomleft: 3px;
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px; }

.skillbar-title span {
  display: block;
  background: rgba(0, 0, 0, 0.1);
  padding: 0 20px;
  height: 35px;
  line-height: 35px;
  -webkit-border-top-left-radius: 3px;
  -webkit-border-bottom-left-radius: 3px;
  -moz-border-radius-topleft: 3px;
  -moz-border-radius-bottomleft: 3px;
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px; }

.skillbar-bar {
  height: 35px;
  width: 0px;
  background: #263238;
  border-radius: 3px;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px; }

.skill-bar-percent {
  position: absolute;
  right: 10px;
  top: 0;
  font-size: 11px;
  height: 35px;
  line-height: 35px;
  color: #ffffff;
  color: rgba(0, 0, 0, 0.4); }

/**/
.MuiPaper-root .MuiTypography-subtitle1 {
  font-size: 36px;
  font-family: "Quattrocento Sans"; }

.MuiButtonGroup-contained {
  box-shadow: none !important; }

.display-projects {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
  flex-direction: column;
  align-items: center; }
  @media (min-width: 768px) {
    .display-projects {
      display: grid;
      margin: 20px 0;
      grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
      grid-gap: 2rem 1rem;
      padding: 0 10px; } }
  .display-projects .MuiCardMedia-root {
    background-size: cover; }
  .display-projects .MuiTypography-subtitle1 {
    font-size: 20px; }
  .display-projects.odd {
    background: #EEE;
    padding-top: 20px;
    padding-bottom: 20px; }

.modal-projects .MuiTypography-h6 {
  font-size: 30px;
  color: var(--main-color);
  font-weight: 700;
  font-family: "Quattrocento Sans"; }

.modal-projects .description-info-modal h2 {
  text-align: left;
  font-size: 25px; }

.modal-projects .MuiButton-label {
  background: var(--main-color);
  color: #FFF;
  padding: 15px 20px;
  border-radius: 10px;
  cursor: pointer; }

.modal-projects .MuiCardMedia-root {
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
  width: 100%;
  height: 210px;
  background-position: center;
  background-size: 94%; }

@media (min-width: 990px) {
  .MuiCardMedia-root {
    height: 600px; } }

.modal-projects .MuiDialogTitle-root {
  padding-left: 0;
  padding-right: 0; }

.modal-projects .makeStyles-descriptionModal-6 {
  text-align: left; }

.modal-projects .MuiDialogContent-root {
  overflow: unset; }

.MuiDialogContent-root {
  overflow-y: unset; }

.MuiDialogTitle-root {
  text-align: center; }

.MuiFormControl-root {
  margin-bottom: 10px; }

.grid-error .MuiGrid-item {
  color: red;
  font-size: 12px; }

.input-msg .MuiInputBase-formControl {
  min-height: 180px; }

.contact-info {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
  flex-direction: column;
  -webkit-justify-content: center;
  justify-content: center; }
  @media (min-width: 768px) {
    .contact-info {
      padding-left: 80px; } }
  .contact-info p {
    display: -webkit-flex;
    display: flex;
    color: #000; }
    .contact-info p svg {
      margin-right: 10px; }
  .contact-info a {
    color: #000;
    text-decoration: none; }
    .contact-info a:hover {
      color: blue; }

:root {
  --main-color:#26a69a; }

footer {
  margin-top: auto;
  padding: 10px;
  background-color: #424242; }
  footer p {
    color: #FFF; }
